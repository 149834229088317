<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          Repair Form: {{ editedItem.wellname }}<br />
        </v-card-title>
        <v-card-text>
          <v-row style="margin-top: 4px">
            <v-col cols="12" sm="5" md="5">
              <v-select
                :items="list_details"
                v-model="component"
                item-value="id"
                item-text="component"
                label="Element"
                dense
                :key="tkey"
                return-object
                @change="element_change"
                :rules="[(v) => !!v || 'Repaired Element required']"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="component.id"
                :items="user_list"
                v-model="component.agent_id"
                item-value="id"
                :readonly="status == 6 || status == 7"
                item-text="name"
                label="Repair by"
                :rules="[(v) => !!v || 'Repaired by required']"
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <datepicker
                v-if="component.id"
                label="Repair Date"
                :edit="true"
                v-model="component.repair_date"
                :rules="[(v) => !!v || 'Repair Date required']"
                :clearable="false"
                :key="cs"
              ></datepicker>
            </v-col>
          </v-row>

          <div :key="fkey" v-if="component.id">
            <v-row
              ><v-col cols="12" sm="5" md="5" dense>
                <h3>Failure list:</h3>
                <br />
                <v-row
                  class="rowh"
                  :key="failure.id"
                  v-for="failure in failures.filter(
                    (x) => x.cmp_code == component.cmp_code
                  )"
                  ><h4>-{{ failure.issue }}</h4></v-row
                >
                <br />
              </v-col>
              <v-col cols="12" sm="7" md="7" dense>
                <h3>Comment:</h3>
                <v-textarea
                  align-self="center"
                  hide-spin-buttons
                  autocomplete="off"
                  v-model="component.comment"
                  :readonly="status == 6 || status == 7"
                  outlined
                  dense
                  rows="2"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row dense  no-gutters>
              <v-col cols="12" sm="2" md="2" dense>
                <v-checkbox
                  v-model="component.repared"
                  label="Repaired"
                  :readonly="status == 7"
                  v-if="status == 6 || status == 7"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="12" sm="3" md="3" dense>
                <v-checkbox
                  v-model="component.with_test"
                  label="Requires Test"
                  :readonly="status == 7"
                  v-if="status == 6 || status == 7"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <filelist
              v-if="list_details.length > 0"
              :item="component"
              :editer="true"
              :doc_type="1"
              :isform="false"
              :auth="'01007'"
              :well_id="parseInt(component.well_id)"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="accept_daialog(true)"
            v-if="status == 6"
          >
            Accept
          </v-btn>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="accept_daialog(false)"
            v-if="status == 6"
          >
            Return
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="save(6)"
            v-if="status <= 5 || status == 8"
          >
            Completed
          </v-btn>
          <v-btn
            class="ma-1"
            color="blue darken-1"
            @click.stop="save(status)"
            v-if="status != 7 && status != 6"
          >
            Save
          </v-btn>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form ref="form" lazy-validation>
      <v-dialog
        v-model="dialog_accept"
        max-width="600px"
        persistent
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-text>
            <br />

            {{ accept ? "Conclusion" : "Justification" }}
            <v-textarea
              ref="comment"
              outlined
              dense
              v-model="accept_comment"
              :rules="[
                (v) =>
                  !!v ||
                  (accept ? 'Conclusion' : 'Justification') + ' required',
              ]"
              variant="outlined"
              auto-grow
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              v-if="accept"
              class="ma-1"
              color="blue darken-1"
              @click.stop="save(7)"
            >
              Accept
            </v-btn>

            <v-btn
              v-if="!accept"
              class="ma-1"
              color="blue darken-1"
              @click.stop="save(8)"
            >
              Return
            </v-btn>
            <v-btn
              color="blue darken-1"
              @click.stop="accept_dialog_close()"
              class="ma-1"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <confirmdialog ref="validation" />
  </v-form>
</template>
<script>
import SaveReparation from "../graphql/Reparation/SaveReparation.gql";
import getElements_list from "../graphql/Reparation/DATA.gql";
export default {
  components: {
    filelist: () => import("./FileList.vue"),
    datepicker: () => import("../components/DatePicker.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    dialog: Boolean,
    item: Object,
    details: Array,
    wells: Array,
    user_list: Array,
    isNotified: { type: Number, default: 0 },
  },
  data() {
    return {
      component: {
        id: null,
        agent_id: null,
        repair_date: null,
      },
      fkey: 2000,
      selected_tab: -1,
      tab: null,
      accept: true,
      dialog_accept: false,
      accept_comment: "",
      list_details: [],
      failures: [],
      barriers: [],
      editedItem: {},
      klist: 2000,
      klist2: 500,
      tkey: 5000,
      selected: false,
      cs: 1000,
      headers: [{ value: "component", selected: true }],
      headers_fail: [{ value: "issue", selected: true }],
      status: 5,
    };
  },
  watch: {},
  mounted() {
    if (this.dialog) {
      this.selected_tab = 0;
      this.status = 5;

      this.editedItem = Object.assign({}, this.item);
      this.getdetails(this.editedItem.id);
    }
  },
  computed: {
    today() {
      return this.$store.state.today;
    },
    users_exe() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("06003"));
      return l;
    },
    users_eval() {
      let l = [];
      l = this.user_list.filter((elm) => elm.autorisations.includes("07003"));
      return l;
    },
  },
  methods: {
    element_change() {
      this.fkey++;
      this.status = this.component.statut_id;
      this.cs++;
    },
    add_new_elements(elements) {
      if (elements.length > 0)
        if (!this.editedItem.details) this.editedItem.details = [];
      elements.forEach((x) => {
        if (this.editedItem.details.findIndex((y) => y.cmp_id == x.id) < 0)
          this.editedItem.details.push({ cmp_id: x.id, cmp_code: x.cmp_code });
      });
      this.tkey++;
    },
    change_well(item) {
      if (item) {
        this.getdetails(item);
      }
    },
    closeDialog() {
      this.$emit("close_reparation");
    },
    row_selected() {},
    async getdetails(id) {
      let r = await this.$apollo.query({
        query: getElements_list,
        variables: { Well_id: parseInt(id) },
        fetchPolicy: "no-cache",
      });
      if (r) {
        this.failures = r.data.getfailures_list;
        this.barriers = r.data.wmf_list;
        if (this.status < 6 || this.status == 8) {
          this.list_details = r.data.getElements_list;
          this.add_new_elements(this.list_details);
        }
        this.selected_tab = 0;
        this.tkey++;
      }
    },
    accept_dialog_close() {
      this.dialog_accept = false;
    },
    accept_daialog(value) {
      this.accept = value;
      this.dialog_accept = true;
    },
    recalc_failures() {
      let wel_failures = [];
      this.failures.forEach((x) => {
        if (
          x.failure_id > 0 &&
          x.statut_id < 2 &&
          x.cmp_code != this.component.cmp_code
        )
          wel_failures.push(x);
      });
      let failure = { cmp_code: "-1" };
      let multiples = this.$repaire_failure(wel_failures, this.barriers);

      // update multiples
      if (multiples.length > 0) {
        failure = multiples.reduce(function (prev, current) {
          if (+current.failure_taux < +prev.failure_taux) {
            return current;
          } else {
            return prev;
          }
        });
      }
      return failure;
    },
    async save(status_id) {
      if (this.$refs.form.validate()) {
        var min = this.failures.reduce(function (prev, current) {
          if (+current.due_date < +prev.due_date) {
            return current;
          } else {
            return prev;
          }
        });
        let failure = null;
        let duedate = min.repair_date;
        if (status_id == 7) failure = await this.recalc_failures();
        this.component.due_date = duedate;
        let c = {
          cmp_id: this.component.cmp_id ? this.component.cmp_id : -1,
          well_tubular_id: this.component.well_tubular_id
            ? this.component.well_tubular_id
            : -1,
          repair_date: this.component.repair_date,
          agent_id: this.component.agent_id,
          well: this.editedItem.id,
          comment: this.component.comment,
          with_test: this.with_test,
          repared: this.component.repared,
          duedate: duedate,
        };
        if (this.component.id > 0) c.id = this.component.id;
        this.$apollo
          .query({
            query: SaveReparation,
            variables: {
              reparation: c,
              statut_id: status_id,
              failures: failure,
              // comment: null,
              user_id: parseInt(this.$store.state.me.id),
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            this.component.id = data.data.SaveRepair.id;
            this.component.status_id = status_id;
            this.status = status_id;
            this.fkey++;
            // this.list_details = this.editedItem.details;
            // if (this.component.id > 0) this.status = this.component.statut_id;
            this.dialog_accept = false;
            this.$store.state.changed = true;
            this.$emit("refresh_reparation");
            if (this.status > 6) this.$emit("close_reparation");
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.rowh {
  margin-left: 12px;
  height: 30px !important;
}
</style>
